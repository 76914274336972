import React from 'react';
import {
  Accordion,
  Column,
  Container,
  Layout,
  LayoutBlock,
  Hero,
  Title,
  Description,
  Row,
  Section,
  Actions,
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO, TypeForm, FeatureRow } from '../../components/_index';

import Archive from '../../assets/images/icons/archive.svg';
import Brain from '../../assets/images/icons/brain-thought.svg';
import Eye from '../../assets/images/icons/eye.svg';
import Touch from '../../assets/images/icons/touch.svg';
import Twitter from '../../assets/images/icons/twitter-outline.svg';
import Website from '../../assets/images/icons/website.svg';

const Eternal = () => {
  return (
    <PageLayout>
      <SEO
        title="Eternal - Capture, Archive, Sign, and Timestamp Anything"
        description="Every chain of custody is secured by the combined network security of Bitcoin, Ethereum,
        Binance Chain, and Ethereum Classic blockchains."
      />
      <Layout dark background="blue">
        <Hero type="code">
          <div className="caption">Eternal</div>
          <Title color="white">
            Harness the Power of Blockchain to Capture, Archive, Sign, and Timestamp Anything
          </Title>
          <Description color="blue-light">
            Everything going through Eternal is hashed with the combined network security of multiple public
            networks.
          </Description>
        </Hero>
      </Layout>

      <LayoutBlock separator>
        <Container>
          <Row gap="small">
            <Column span={6}>
              <Section title="Features" />
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                Icon: Archive,
                title: 'Archiving',
                body: 'Eternalize any content on the web.',
              },
              {
                Icon: Eye,
                title: 'Censorship Resistance',
                body: 'Provides proof of the original state of content anywhere on the web.',
              },
              {
                Icon: Touch,
                title: 'Legal Proof',
                body: 'Capture proof of document state at a point in time.',
              },
              {
                Icon: Twitter,
                title: 'Eternal Tweets',
                body:
                  'Capture tweets from anyone on Twitter and Cryptographically sign your own tweets and threads.',
              },
              {
                Icon: Website,
                title: 'Eternal Proof Report',
                body:
                  'Provide direct and measurable proof that the Eternalized info was stored on chain at the declared point in time.',
              },
              {
                Icon: Brain,
                title: 'Predictions',
                body:
                  'Eternalize a prediction, share later with hundreds of millions of dollars of measurable proof.',
              },
            ]}
          />
          <Row gap="large">
            <Column span={12}>
              <Accordion title="Frequently Asked Questions">
                <Accordion.Item title="Will I be able to download all of my Eternalized content?">
                  After you Eternalize something, you can download the entire transaction proof report for
                  safe keeping, yes.
                </Accordion.Item>
                <Accordion.Item title="Is it free?">There are free and premium plans.</Accordion.Item>
                <Accordion.Item title="Can anyone use Eternal?">Absolutely.</Accordion.Item>
                <Accordion.Item title="Is it made public when something is Eternalized?">
                  No, but it is stored on a shared node so don’t send super sensitive info in the free
                  version. With business plans, you control your own data.
                </Accordion.Item>
                <Accordion.Item title="What can you do with the hash?">
                  The hash can be shared to prove you Eternalized something, without exposing the actual
                  information. When you want to reveal what was Eternalized you can share the full transaction
                  and link to the Eternal Proof Report.
                </Accordion.Item>
              </Accordion>
            </Column>
          </Row>
          <Row>
            <Column span={6}>
              <Section title="Need more features?">
                Are the free and premium Eternal plans not enough? Schedule a call with the creators to find
                out how the Eternal features you need can complement your existing systems on a larger scale.
              </Section>
            </Column>
            <Column span={6} right middle>
            <Actions
            actions={[
              {
                type: 'link',
                name: 'Contact Us',
                color: 'blue',
                hover: 'blue-dark',
                to: '/contact-us',
              },
            ]}
          />
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default Eternal;
